<template>
  <div>
   <div class="bannerpng">同鑫独家精准分析，助你更高投资成就</div>
    <div class="w1400">
      <div class="clearfix leimulist">
        <div class="clearfix">
          <span  style="padding-right: 0">类目：</span>
          <div style="overflow: hidden">
             <span v-for="(leimuarr, index) in leimuarrs"
                   @click="changeslm(index,leimuarr.id)"
                   v-bind:class="{ active: index == currentlm }"
             >{{leimuarr.name}}</span>
          </div>
        </div>
        <div style="margin-top: 10px" class="clearfix">
          <span style="padding-right: 0">地区：</span>
          <div style="overflow: hidden">
             <span v-for="(leimuarr, index) in addresslist"
                   @click="changesdq(index,leimuarr.id)"
                   v-bind:class="{active: index == currentdq }"
             >{{leimuarr.name}}</span>
          </div>
        </div>
      </div>
      <div class="fengleilist clearfix">
        <div class="flistleft">
          <div class="tablebottom">
            <div class="timechose clearfix">
              <ul class="infortitle clearfix">
                <li  :class="{'active':currentli==0}" @click="tjbtnclick">全部信息</li>
                <li  :class="{'active':currentli==1}" @click="newsbtnclick">我的关注</li>
              </ul>
              <div class="inforall">
                <ul>
                  <li class="inforlist" v-for="(iteams,index) in inforlist">
                    <div class="listtitle clearfix" @click="gcchanges(iteams.id)">
                      <span>{{iteams.baseType}}</span>
                      <span>{{iteams.name}}</span>
                      <span><img src="~assets/img/dw.jpg"/>&nbsp;&nbsp;{{iteams.proName}}</span>
                    </div>
                    <ul class="infornews" v-for="(iteam,index) in iteams.wechatList">
                      <li class="clearfix" @click="gcchanges(iteam.subjectId)"><span><strong>[新闻]</strong>{{iteam.title}}</span><span>{{formatDate(iteam.create)}}</span></li>
                    </ul>
                  </li>
                </ul>
              </div>
            </div>
            <Page :total="count" @on-change="changenext"  show-elevator style="margin:22px 0 180px 0;float: right"></Page>
          </div>
        </div>
        <div class="flistright">
            <div class="righttop">
              <p>APP扫码下载</p>
              <img src="~assets/img/public/QRcode_app.png" style="width: 150px;height: 150px;margin: 0 auto;display: block"/>
              <span>随时随地 关注行情</span>
            </div>
          <div  class="rightbottom">
            <p>最新调价</p>
            <div @click.stop="handleImgClick($event)">
              <!--<vue-seamless-scroll :data="fgtjarrs" style="height:412px;overflow: hidden;border: 1px solid #E6EBF5;" :class-option="defaultOption">-->
                <ul class="tiaojiabody" style="height:380px;overflow: hidden;border: 1px solid #E6EBF5;">
                  <li class="clearfix" v-for="(fgtjarr, index) in fgtjarrs" :id="fgtjarr.subId" :title="fgtjarr.title">
                    <span class="fl"></span> [{{fgtjarr.name}}]{{fgtjarr.title}}
                  </li>
                </ul>
              <!--</vue-seamless-scroll>-->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import NavHead from '@/components/content/mainTabbar/NavHead'
  import LogoBar from '@/components/content/mainTabbar/LogoBar'
  import {getSlpProvinceApi,getSteelScrapApi,getPriceNewsApi} from 'network/home'
export default {
  name: 'Marketprice',
  data() {
    var self = this // 存储当前vue实例this,这行是重
    return {
      token:"",
      setVip:"",
      leimuarrs:[
        {name:"全部",id:""},
        {name:"基地",id:1},
        {name:"钢厂",id:0},
      ],
      addresslist:[],
      provinceid:"",
      typeid:"",
      gps:"",
      currentlm:0,//行情价格默认第一个
      currentdq:0,//行情价格默认第一个
      currentli:0,//行情价格默认第一个
      page:1,//默认第一页
      count:1,//总页数
      fgtjarrs:[],//热门发布
      inforlist:[
        {
          title:"广西贺州桂鑫钢企",
          type:"钢厂",
          address:"上海",
          list:[
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            },
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            }
          ]
        },
        {
          title:"广西贺州桂鑫钢企",
          type:"钢厂",
          address:"上海",
          list:[
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            },
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            }
          ]
        },
        {
          title:"广西贺州桂鑫钢企",
          type:"钢厂",
          address:"上海",
          list:[
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            },
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            },
            {
              content:"3月18日唐山国义特钢马蹄",
              time:"09.13 18:00"
            }
          ]
        }
      ]
    }
  },
  components: {
    NavHead,
    LogoBar
  },
  computed: {
    defaultOption () {
      return {
        step: 0.6, // 数值越大速度滚动越快
        // limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        // hoverStop: true, // 是否开启鼠标悬停stop
        // direction: 1, // 0向下 1向上 2向左 3向右
        // openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 1000 // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  created () {
    this.token = this.$store.state.token
    this.setVip=localStorage.getItem('setVip')
    this.getSlpProvince()
    this.getSteelScrap()
    this.getPriceNews()
  },
  methods: {
    changeslm(index,id){
      this.currentlm = index;
      if(this.currentli==1){
        this.typeid=""
      }else{
        this.typeid=id
      }
      this.getSteelScrap()
    },
    changesdq(index,id){
      this.currentdq =index;
      if(this.currentli==1){
        this.provinceid=""
      }else{
        this.provinceid=id
      }
      this.getSteelScrap()
    },
    tjbtnclick(){
      this.page=1;
      this.currentli=0;
      this.currentlm =0;
      this.currentdq =0;
      this.gps="";
      this.getSteelScrap()
    },
    newsbtnclick(){
      this.page=1;
      this.currentli=1;
      this.currentlm =0;
      this.currentdq =0;
      this.provinceid="";
      this.typeid="";
      this.gps=0;
      this.getSteelScrap()
    },
    changenext(page){
      this.page=page
      this.getSteelScrap()
    },
    // 获取地区
    getSlpProvince(){
      let data = {

      }
      getSlpProvinceApi(data).then((res) => {
        if(res.code==0){
          var datalist=res.data
            datalist.unshift({id: "",name: "全部",pid: "1",children: null})
            this.addresslist=datalist
        }else{
            this.$Message.warning(res.errInfo)
       }
      })
    },
    // 获取地区
    getSteelScrap(){
      let data = {
        province:this.provinceid,
        type:this.typeid,
        gps:this.gps,
        num:this.page,
        size:5
      }
      getSteelScrapApi(data).then((res) => {
        if(res.code==0){
        this.inforlist=res.data
        this.count=res.count
        }else{
        this.$Message.warning(res.errInfo)
        }
    })
    },
    // 时间转换
    formatDate(date){ //设置时间转换格式
      var datetime=new Date(date)
      var year = datetime.getFullYear();
      var month = datetime.getMonth()+1;//js从0开始取
      var date = datetime.getDate();
      var hour = datetime.getHours();
      var m = datetime.getMinutes();
      month = month < 10 ?  "0" + month : month ;
      date  = date<10 ?  "0" + date : date;
      hour  = hour <10 ? "0" + hour : hour;
      m  = m <10 ? "0" + m : m;
      return year + "-" + month + "-" + date + ' ' + hour + ':' + m ;
    },
    gcchanges(val){
      if(this.token){
        this.$router.push({path:'/scrapdetail',query: {subId:val}})
      }else{
        this.$router.push({ path:'/login' })
      }
    },
    handleImgClick(e) {
      let id = e.target.id;
      if(this.token){
        this.$router.push({path:'/scrapdetail',query: {subId:id}})
      }else{
        this.$router.push({ path:'/login' })
      }
    },
    // 获取废钢调价
    getPriceNews(){
      let data = {
        num:1,
        size:10
      }
      getPriceNewsApi(data).then((res) => {
        if(res.code==0){
        this.fgtjarrs=res.data
      }else{
        this.$Message.warning(res.errInfo)
      }
    })
    },
  }
}
</script>

<style scoped>
.bannerpng{
  width: 100%;
  height:180px;
  background: url("~assets/img/1920.png");
  background-color:#3D60BB;
  text-align: center;
  line-height: 180px;
  color: #fff;
  font-size: 40px;
  font-family: MicrosoftYaHei;
}
  .leimulist{
    border: 1px solid #e6ebf5;
    margin-top: 50px;
    padding: 20px;
  }
.leimulist span{
  line-height: 30px;
  font-size: 14px;
  cursor: pointer;
  float: left;
  padding: 0 10px;
}
.leimulist span.active{
  display: flex;
  justify-content: center;
  color: #fff;
  background-color: #25449A;
  border-radius: 4px;
}
.fengleilist{
  margin-bottom: 60px;
}
  .flistleft{
    width: 800px;
    float: left;
  }
  .flistright{
    width: 350px;
    float: right;
  }
.flistright .righttop{
background: #25449A;
  border-radius: 5px;
  margin-top: 38px;
  overflow: hidden;
  }
.flistright .righttop p{
  text-align: center;
  line-height: 84px;
  font-size: 18px;
  color: #fff;
}
.flistright .righttop span{
  display: block;
  font-size: 18px;
  color: #303133;
  line-height: 60px;
  text-align: center;
  background: #D9EBFF;
  margin-top: 30px;
}
.flistright .rightbottom{
  margin-top: 23px;
}
.flistright .rightbottom p{
  line-height: 40px;
  background: #E6EBF5;
  border-left: 3px solid #25449A;
  font-size: 15px;
  padding-left: 18px;
}
.tiaojiabody{
  height: 380px;
  padding: 0px 8px;
  font-size: 14px;
  overflow: hidden;
}
.tiaojiabody li{
  line-height: 38px;
  cursor: pointer;
  width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  list-style: disc;
}
.tiaojiabody li:hover{
  color: #25449A;
  text-decoration: underline;
}
.tiaojiabody li span.fl:before {
  content: ".";
  width: 4px;
  height: 4px;
  display: inline-block;
  border-radius: 50%;
  background: #000;
  vertical-align: middle;
  margin-right: 15px;
}
.tablebottom .timechose{
  border-bottom: 1px solid #E6EBF5;
  cursor: pointer;
  margin-top: 22px;
}
.tablebottom .timechose .infortitle{
  border-bottom: 1px solid #E6EBF5;
}
.tablebottom .timechose ul.infortitle li{
  width: 66px;
  float: left;
  font-size: 16px;
  text-align: center;
  line-height: 46px;
}
.tablebottom .timechose ul.infortitle li:nth-child(1){
  margin-right: 20px;
}
.tablebottom .timechose ul.infortitle li.active{
  position: relative;
  top: 1px;
  color: #25449A;
  border-bottom: 2px solid #25449A;
}
.inforall{
  margin-top: 25px;
}
  .inforall .inforlist{
    border: 1px solid #E6EBF5;
    padding:10px 20px 15px;
    font-size: 16px;
    margin-bottom: 25px;
  }
.inforall .inforlist .listtitle{
  margin: 10px 0;
}
.inforall .inforlist .listtitle span:nth-child(1){
  float: left;
  font-size: 12px;
  color: #25449A;
  background: #DCEDFF;
  line-height: 24px;
  padding: 0 5px;
  border-radius: 2px;
}
.inforall .inforlist .listtitle span:nth-child(2){
  color: #25449A;
  font-weight: bold;
  margin-left: 12px;
  float: left;
}
.inforall .inforlist .listtitle span:nth-child(3){
  float: right;
  font-size: 14px;
}
.inforall .inforlist  .infornews li{
  line-height: 38px;
}
.inforall .inforlist  .infornews li span:nth-child(1){
 float: left;
}
.inforall .inforlist  .infornews li span:nth-child(2){
  float: right;
}
</style>
<style>
  /*.ivu-table-column-center .tubiao{*/
    /*cursor:pointer;*/
    /*display:block;*/
    /*width:22px;*/
    /*height:20px;*/
    /*background: url('~assets/img/tbpng.png');*/
    /*margin: 0 auto;*/
  /*}*/
  .flistleft .ivu-table th{
    background-color: #E6EBF5;
  }
  .flistleft .ivu-table-stripe .ivu-table-body tr:nth-child(2n) td, .flistleft  .ivu-table-stripe .ivu-table-fixed-body tr:nth-child(2n) td{
    background-color:#F4F6FB
  }
</style>
